import { default as React } from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { CgArrowLongRight } from 'react-icons/cg'

const Callout = ({ callout: { title = '', subtitle = '', description = '', image: { file, position = true }, button: { text = '', url = '' } } }) => {
  const external = url.match(/^https?:\/\//)

  return (
    <section className="py-24">
      <div className="container mx-auto px-8">
        <div className="relative flex flex-wrap -mx-8 py-6">
          <div
            className={`absolute bg-gray-100 h-full w-full md:w-2/3 top-0 ${position ? 'right-0' : 'left-0'}`} />
          <div
            className={`w-full md:w-1/2 p-12 ${position ? 'order-1' : 'order-3'} relative z-10`}>
            <GatsbyImage
              className="w-full rounded-sm"
              image={getImage(file?.localFile)}
              alt={title} />
          </div>
          <div
            className="w-full md:w-1/2 p-12 order-2 flex flex-col justify-center items-center text-center relative z-10">
            <span
              className="text-xs font-medium uppercase text-secondary mb-4">
              {subtitle}
            </span>
            <h2
              className="text-6xl text-primary mb-8">
              {title}
            </h2>
            <p
              className="leading-loose mb-12">{description}</p>
            <div>
              {external ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={url}
                  className="btn btn-secondary">
                  <span className="mr-4">{text}</span>
                  <CgArrowLongRight />
                </a>
              ) : (
                <Link
                  className="btn btn-secondary"
                  to={url}>
                  <span className="mr-4">{text}</span>
                  <CgArrowLongRight />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Callout