import {default as React} from "react"
import moment from "moment"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import {CgArrowLongRight} from "react-icons/cg"


const ArticleCard = ({ post: { slug = '', title = '', excerpt = '', tags: { nodes: tags = []}, date, featuredImage }}) => {
  const formattedDate = moment(date).format('MMMM DD, YYYY')
  return (
    <div
      className="h-full flex flex-col rounded-t-md overflow-hidden shadow-xl">
      <GatsbyImage image={getImage(featuredImage?.node?.localFile)} alt={title} />
      <div
        className="flex flex-col flex-grow p-6 bg-white">
        <small>{ formattedDate }</small>
        <h1
          className="text-2xl my-4">{ title }</h1>
        <div 
          dangerouslySetInnerHTML={{
            __html: excerpt
          }}/>
        <div
          className="mt-auto mb-4">
          <Link
            to={`/articles/${slug}`}
            className="flex items-center text-secondary underline">
            <span className="mr-3">Continue Reading</span>
            <CgArrowLongRight />
            </Link>
        </div>
      </div>
    </div>
  )
}

export default ArticleCard
