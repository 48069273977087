import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Slide = ({ slide: { title = '', content = '', button = { text: '', url: '' }}, image}) => {
  return (
    <div className="relative">
      <GatsbyImage
        alt={title}
        image={getImage(image)} />
      <div
        className="relative md:absolute z-10 inset-0 flex flex-col justify-end p-8 md:p-20 bg-white md:bg-transparent text-primary">
        <h2 className="text-3xl md:text-6xl">{ title }</h2>
        <p className="my-8">{content}</p>
        <div>
          <Link
            className="btn btn-secondary"
            to={button.url}>
            {button.text}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Slide