import { default as React, useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import {default as _} from "lodash"
import TestimonialCard from "~/components/testimonial/card"
import DotsIcon from "~/images/dots.inline.svg"
import QuoteIcon from "~/images/icons/quote.inline.svg"

const TestimonialSection = () => {
  const { allWpTestimonial: { nodes: testimonials } } = useStaticQuery(graphql`{
    allWpTestimonial{
      nodes {
        title
        content
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
              }
            }
          }
        }
        testimonialFields {
          backgroundColor
        }
      }
    }
  }`)
  const [random, setRandom] = useState(testimonials)
  useEffect(() => {
    setRandom(_.shuffle(testimonials))
  }, [testimonials])
  const [testimonialOne, testimonialTwo, testimonialThree] = random
  return (
    <section className="py-24 relative">
      <div className="flex flex-wrap relative z-10 px-8">
        <div className="relative w-full md:w-1/4 px-8 mb-12">
          <QuoteIcon
            className="absolute w-24 top-0 left-0 -mt-8 -ml-6" />
          <h1
            className="text-5xl text-primary mb-6">
            What People Say About Us
            </h1>
          <span>We are grateful for our clients.</span>
        </div>
        <div className="w-full md:w-3/4 flex flex-col px-8">
          <div
            className="flex justify-center md:mb-10">
            <TestimonialCard
              testimonial={testimonialOne} />
          </div>
          <div
            className="flex flex-wrap justify-center -mx-8">
            <div
              className="w-full md:w-1/2 px-8">
              <TestimonialCard
                testimonial={testimonialTwo} />
            </div>
            <div
              className="w-full md:w-1/2 px-8">
              <TestimonialCard
                testimonial={testimonialThree} />
            </div>
          </div>
        </div>
      </div>
      <DotsIcon
        className="absolute w-32 top-1/2 right-10 transform -translate-y-16" />
    </section>
  )
}

export default TestimonialSection