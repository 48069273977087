/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {CgArrowLongRight} from 'react-icons/cg'
import Layout from "~/components/layout"
import Seo from "~/components/seo"
import Swiper from "~/components/swiper"
import Slide from "~/components/slide"
import DotsIcon from "~/images/dots.inline.svg"
import Service from "~/components/home/service"
import Feature from "~/components/home/feature"
import Stat from "~/components/home/stat"
import Callout from "~/components/callout"
import TestimonialSection from "~/components/testimonial"
import Articles from "~/components/articles"

const HomePage = ({ data: { home, images, resources } }) => (
  <Layout
    home={true}>
    <Seo title="Home" />
    <section
      sx={{
        borderBottomRightRadius: '50% 10%'
      }}
      className="relative pt-48 pb-12 md:pb-20 bg-primary">
      <div className="container mx-auto px-8">
        <div className="flex flex-wrap -mx-4">
          <div className="hidden md:block w-full md:w-1/3 px-4 pb-12">
            <div className="relative rounded-t-2xl h-full overflow-hidden">
              <GatsbyImage
                className="h-full"
                image={getImage(images.nodes.find(({ name }) => name === 'hero-one-2x'))}
                alt="Personal, Business, Wealth" />
              <ul
                className="absolute bottom-12 list-none left-12 p-0 m-0 z-10 font-heading font-bold text-primary text-2xl md:text-6xl">
                <li className="my-4">Personal</li>
                <li className="my-4">Business</li>
                <li className="my-4">Wealth</li>
              </ul>
            </div>
          </div>
          <div className="w-full md:w-2/3 px-4">
            <div className="relative rounded-t-2xl overflow-hidden">
              <Swiper
                loop={true}
                slides={home.homePage.slide.map((slide, i) => <Slide key={i} slide={slide} image={slide.image.localFile} />)} />
            </div>
          </div>
        </div>
      </div>
      <DotsIcon
        className="hidden md:block absolute w-32 h-32 bottom-12 left-40 z-10" />
    </section>
    <section className="py-24">
      <div className="container mx-auto px-8">
        <div className="flex flex-wrap -mx-8">
          {home.homePage.services.map((service, i) => (
            <div
              key={i}
              className="w-full md:w-1/3 px-8 mb-12 md:mb-0">
              <Service
                {...service} />
            </div>
          ))}
        </div>
      </div>
    </section>
    <section className="py-24">
      <div className="container mx-auto px-8">
        <Feature
          feature={home.homePage.feature} />
      </div>
    </section>
    <section className="pb-20">
      <div className="container mx-auto px-8">
        <div className="flex flex-wrap -mx-8">
          {home.homePage.stats.map((stat, i) => (
            <div
              key={i}
              className="w-full md:w-1/3 px-8">
              <Stat {...stat} />
            </div>
          ))}
        </div>
      </div>
    </section>
    {/** Callout Section */}
    { home.callout && (
      <Callout
        callout={home.callout} />
    )}
    {/** Testimonial Section */}
    <TestimonialSection /> 
    {/** History Section */}
    <section className="relative py-16 mb-20">
      <div className="relative container mx-auto px-8 z-10">
        <div className="flex flex-wrap -mx-8">
          <div className="w-full md:w-1/2 px-8">
            <div
              className="flex flex-col mb-12">
              <h2
                className="text-5xl text-primary">
                {home.homePage.history.title}
              </h2>
              <div className="flex items-center my-6">
                <div className="w-1/3 h-1.5 bg-gray-200" />
                <div className="w-1/3 h-0 border-b border-gray-200" />
              </div>
              <p>{home.homePage.history.description}</p>
              <div
                className="mt-6">
                <Link
                  to={`/who-we-are`}
                  className="btn btn-secondary">
                  <span className="mr-4">Get to know us</span>
                  <CgArrowLongRight />
                </Link>
              </div>
            </div>
            <div
              className="">
              <h4
                className="inline-block text-4xl pb-6 mb-6 border-b-2">
                We are SOLID
              </h4>
              <ul
                className="flex max-w-max flex-wrap px-0 py-4 m-0 list-none">
                {home.homePage.history.solid.map(({ value }, i) => (
                  <Fragment
                    key={`${value}-${i}`}>
                    { i === 3 && <li className="w-full" /> }
                    <li
                      className={`text-secondary uppercase text-lg font-medium mr-6 mb-4${i === 3 ? ' ml-5' : ''}`}>
                      { value }
                    </li>
                  </Fragment>
                ))}
              </ul>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-8 flex justify-end">
            <GatsbyImage
              className="w-96"
              image={getImage(home.homePage.history.image?.localFile)}
              alt="Kirkwood Bank Locations" />
          </div>
        </div>
      </div>
      <div
        className="hidden md:block absolute w-1/2 md:w-1/4 bg-sand top-0 right-0 h-full" />
    </section>
    {/** Resource Section */} 
    <Articles />
  </Layout>
)

export const query = graphql`
  query HomePageQuery($databaseId: Int!){
    images: allFile(filter: { relativeDirectory: { eq: "home"}}){
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH 
            placeholder: BLURRED
            quality: 100
          )
        }
      }
    }
    home: wpPage(databaseId: { eq: $databaseId }){
      databaseId
      title
      homePage {
        slide {
          title
          content
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
              }
            }
          }
          button {
            text
            url
          }
        }
        services {
          title
          description
          icon
          button {
            text
            url
          }
        }
        feature {
          title
          subtitle
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
              }
            }
          }
          items {
            title
            icon
            url
          }
        }
        stats {
          number
          text
        }
        history {
          title
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
              }
            }
          }
          solid {
            value
          }
        }
      }
      callout {
        title
        subtitle
        description
        image {
          file {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
              }
            }
          }
          position
        }
        button {
          text
          url
        }
      }
    }
  }
`

export default HomePage
