import { default as React } from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { CgArrowLongRight } from 'react-icons/cg'
import DotsIcon from "~/images/dots.inline.svg"
import ChartIcon from "~/images/icons/chart.inline.svg"
import BarsIcon from "~/images/icons/bars.inline.svg"
import BankIcon from "~/images/icons/bank.inline.svg"
import MobileIcon from "~/images/icons/mobile.inline.svg"
import AtmIcon from "~/images/icons/atm.inline.svg"

const icons = {
  pie: ChartIcon,
  bar: BarsIcon,
  bank: BankIcon,
  mobile: MobileIcon,
  atm: AtmIcon
}

const Item = ({ title = '', icon = 'pie', url = ''}) => {
  const Icon = icons[icon]
  return (
    <Link
      to={url}
      className="flex w-full items-center p-8 mb-8 bg-gray-100">
      <Icon className="w-12" /> 
      <h3
        className="ml-6 mr-auto">{ title }</h3>
      <CgArrowLongRight />
    </Link>
  )
}

const Feature = ({ feature: { title = '', subtitle = '', description = '', items = [], image: { localFile } } }) => {

  return (
    <div className="flex flex-wrap">
      <div className="relative w-full md:w-1/2 px-4 md:px-8">
        <DotsIcon
          className="absolute w-32 h-32 top-0 left-0 -mt-12 -ml-10" />
        <GatsbyImage
          className="w-full"
          image={getImage(localFile)}
          alt={title} />
      </div>
      <div className="w-full md:w-1/2 px-4 md:px-8">
        <div
          className="flex flex-col h-full justify-center">
          <h2
            className="text-6xl mt-32 mb-4">
            { title }
          </h2>
          <h4
            className="text-secondary text-3xl mb-6">
            { subtitle }
          </h4>
          <p className="mb-16">
            { description }
          </p>
          <div
            className="flex flex-col">
            { items.map((item, i) => (
              <Item
                key={i}
                {...item} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Feature