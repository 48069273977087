import { default as React } from "react"
import { Link } from "gatsby"          
import {CgArrowLongRight} from 'react-icons/cg'
import ChartIcon from "~/images/icons/chart.inline.svg"
import BarsIcon from "~/images/icons/bars.inline.svg"
import BankIcon from "~/images/icons/bank.inline.svg"

const icons = {
  pie: ChartIcon,
  bar: BarsIcon,
  bank: BankIcon
}
const Service = ({ title = '', description = '', icon = 'bank', button = { text: '', url: '' } }) => {
  const Icon = icons[icon]

  return (
    <div className="flex flex-col h-full">
      <Icon
        className="w-16" />
      <h3
        className="text-3xl my-8">
        { title }
      </h3>
      <p
        className="mb-10">{ description }</p>
      <div>
        <Link
          className="btn btn-secondary"
          to={button.url}>
          <span className="mr-4">{button.text}</span>
          <CgArrowLongRight />
        </Link> 
      </div>
    </div>
  )
}

export default Service