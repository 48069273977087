import { default as React } from "react"

const Stat = ({ number = '', text = '' }) => {
  return (
    <div
      className="flex flex-col items-center justify-center text-center p-4">
      <h2
        className="text-secondary text-6xl mb-8">
        { number }
      </h2>
      <p>{ text }</p>
    </div>
  )
}

export default Stat