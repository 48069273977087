import {default as React} from "react"
import {useStaticQuery, graphql, Link} from "gatsby"
import ArticleCard from "~/components/articles/card"

const ArticleSection = () => {
  const { articles } = useStaticQuery(graphql`
    {
      articles: allWpPost(limit: 3, sort: { fields: date, order: DESC}) {
        nodes {
          databaseId
          title
          slug
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100, aspectRatio:1.333)
                }
              }
            }
          }
          date
          excerpt
          tags {
            nodes {
              name
              termTaxonomyId
            }
          }
        }
      }
    }
  `)
  return (
    <section 
       className="relative py-24">
       <div className="absolute top-0 left-0 h-2/3 w-full bg-gray-100" />
      <div className="container mx-auto px-8 relative z-10">
        <div className="flex flex-wrap mb-12 -mx-8">
          <div
            className="w-full px-8">
            <h1 className="text-5xl">Recent Resources</h1>
          </div>
        </div>
        <div className="flex flex-wrap items-stretchs mb-20 -mx-8">
          {articles.nodes.map(post => (
            <div
              key={post.databaseId}
              className="w-full md:w-1/3 px-8 mb-8">
              <ArticleCard
                post={post} /> 
            </div>
          ))}
        </div>
        <div className="flex flex-wrap justify-center -mx-8">
          <div
            className="px-8">
            <Link
              to={`/resource-center`}
              className="btn btn-secondary">
              Visit Our Resource Center
            </Link>
          </div>
        </div> 
      </div>
    </section>
  )
}

export default ArticleSection